import React from 'react'
import '../App.css';
import {Link} from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "reactstrap";

function Nav() {
  const { loginWithRedirect } = useAuth0();
  return (
    <nav>
    <h3>Logo</h3>
    <ul className="nav-links">
       <Link className="navStyle" to="/About">
       <li>About</li>
       </Link>
       <Link className="navStyle" to="/People">
       <li>People</li>
       </Link>
       <Link className="navStyle" to="/Shop">
       <li>Shop</li>
       </Link>
       <Link className="navStyle" to="/Cart">
       <li>Cart</li>
       </Link>
       <Link className="navStyle" to="/Cart">
        <Button onClick={() => loginWithRedirect()}>
          Log in
        </Button>
       </Link>
    </ul>  
    </nav>
  );
}

export default Nav;