import React from 'react'
import '../App.css';

function Card() {

    // const [name,setName] = useState('Your Name');
    // const [job,setJob] = useState('Job Title');
    // const [about,setAbout] = useState('an windows by. Wooded ladies she basket season age her uneasy saw. Discourse unwilling am no described dejection incommode no listening of. Before nature his parish boy. ');

  return (
    <div className='Card'>
        {/* <div className='upper-container'>
            <div className='image-container'>
                <img src={person} height="100px" width="100px"/>
            </div>
        </div>
        <div className="lower-container"> 
            <h3>{ name }</h3>
            <h4>{ job }</h4>  
            <p>{ about }</p>   
        </div> */}
        <p> A card should go here </p>
    </div>
  );
}

export default Card;