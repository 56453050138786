import React from 'react'
import '../App.css';
import ard from "../arduino.jpg";

function About() {
  return (
    <div>
      <img className="about-img" src={ard} alt="Arduino for background" height="250px" width="auto"/>
      <h1> About Page </h1>  
    </div>
  );
}

export default About;