import React from 'react'
import '../App.css';

function Shop() {
  return (
    <div >
      <h1> Shop Page </h1>
    </div>
  );
}

export default Shop;