import React from 'react';
import '../App.css';

function Footer() {
  return (
    <footer>
        <h3>
            &copy; 2020 <strong> SmartPtr Learning </strong>   
        </h3>
    </footer>
  );
}

export default Footer;