import React from 'react'
import '../App.css';

function Home() {
  return (
    <div >
      <h1> Home </h1>
    </div>
  );
}

export default Home;