import React from 'react'
import './App.css';
import Nav from './Components/Nav';
import Home from './Components/Home';
import About from './Components/About';
import Shop from './Components/Shop';
import Cart from './Components/Cart';
import Footer from './Components/Footer';
import Card from './Components/Card';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'; 

function App() {
  return (
    <Router>
    <div className="App">
      < Nav />
      <Switch>
        < Route path="/" exact component={Home}/>
        < Route path="/About" component={About}/>
        < Route path="/Shop" component={Shop}/>
        < Route path="/Cart" component={Cart}/>
        < Route path="/People" component={Card}/>
      </Switch>
      <Footer/>
    </div>  
    </Router>

  );
}

export default App;
